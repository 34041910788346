import { render, staticRenderFns } from "./batchUpdate.vue?vue&type=template&id=94ce64bc&scoped=true&"
import script from "./batchUpdate.vue?vue&type=script&lang=js&"
export * from "./batchUpdate.vue?vue&type=script&lang=js&"
import style0 from "./batchUpdate.vue?vue&type=style&index=0&id=94ce64bc&scoped=true&lang=css&"
import style1 from "./batchUpdate.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94ce64bc",
  null
  
)

export default component.exports